'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _emojiRegex = require('emoji-regex');

var _emojiRegex2 = _interopRequireDefault(_emojiRegex);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var emojiRegex = (0, _emojiRegex2.default)();

exports.default = {
  isPureEmojiString: function isPureEmojiString(text) {
    if (!text || !text.trim()) {
      return false;
    }

    // Benchmark: http://jsben.ch/#/qpvoO
    return text.replace(emojiRegex, '').trim() === '';
  }
};